// Global header variables.
const layoutBreakpointMedium = 1000;
const layoutBreakpointTiny = 550;

// Add the form thank you pages.
document.addEventListener( 'wpcf7mailsent', function( event ) {
  if ( '718' === event.detail.contactFormId ) {
    location = '/contact/bedankt/';
  }
  if ( '1694' === event.detail.contactFormId ) {
    location = '/zakelijk/contact/bedankt/';
  }
  // Test forms.
  if ( '5257' === event.detail.contactFormId || '6327' === event.detail.contactFormId || '5036' === event.detail.contactFormId ) {
    location = '/contact/bedankt/';
  }
}, false );