// Global header variables.
const identifierBody = document.querySelector('body');
const identifierTop = document.getElementById('top');
const stateMainMenuOpen = 'state-main-menu-open';
const stateShowSubMenu = 'state-show-sub-menu';
const targetSubMenuParent = '.state-has-sub-menu';
const targetProductListsMenu = 'product-lists';
const triggerMainNavigation = document.getElementById('trigger-main-navigation');

function closeOpenSubMenus(targetEntry) {
  targetEntry.forEach(function (closeTarget) {
    if (closeTarget.classList.contains(stateShowSubMenu)) {
      closeTarget.classList.remove(stateShowSubMenu);
    }
  });
}
function closeSiblingSubMenus(topLevelTarget) {
  return Array.prototype.filter.call(topLevelTarget.children, function (sibling) {
    if (sibling.classList.contains(stateShowSubMenu)) {
      sibling.classList.remove(stateShowSubMenu);
      if (sibling.classList.contains(targetProductListsMenu)) {
        closeOpenSubMenus(document.querySelectorAll('.'+targetProductListsMenu+' '+targetSubMenuParent));
      }
    }
  });
}

// Set-up the mobile menu trigger.
triggerMainNavigation.addEventListener('click', function() {
  if (identifierBody.classList.contains(stateMainMenuOpen)) {
    identifierBody.classList.remove(stateMainMenuOpen);
    closeOpenSubMenus(document.querySelectorAll('#main-navigation '+targetSubMenuParent));
    triggerMainNavigation.setAttribute('aria-expanded', 'false');
  } else {
    identifierBody.classList.add(stateMainMenuOpen);
    triggerMainNavigation.setAttribute('aria-expanded', 'true');
  }
});

// Set-up the sub-menu triggers (for lower touch devices).
document.querySelectorAll('#main-navigation '+targetSubMenuParent+' > a').forEach(subMenuTrigger => subMenuTrigger.addEventListener('click', (e) => {
  if (window.innerWidth < layoutBreakpointMedium) {
    let targetParent = e.target.parentNode;
    if (!targetParent.classList.contains(stateShowSubMenu)) {
      e.preventDefault();
      closeSiblingSubMenus(targetParent.parentNode);
      targetParent.classList.add(stateShowSubMenu);
    }
  }
}));

// Set-up the desktop hovers.
document.querySelectorAll('#main-menu > '+targetSubMenuParent).forEach(subMenuTrigger => subMenuTrigger.addEventListener('mouseenter', () => {
  if (window.innerWidth > layoutBreakpointMedium) {
    identifierTop.classList.add(stateShowSubMenu);
  }
}));
document.querySelectorAll('#main-menu > '+targetSubMenuParent).forEach(subMenuTrigger => subMenuTrigger.addEventListener('mouseleave', () => {
  if (window.innerWidth > layoutBreakpointMedium) {
    identifierTop.classList.remove(stateShowSubMenu);
  }
}));