if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

document.addEventListener('DOMContentLoaded', function() {
  // Set some globals
  var lazyloadImages,
    lazyloadSources,
    lazyImgClass = 'lazy',
    lazyParentClass = 'lazy-parent',
    lazySourceTarget = '.'+lazyParentClass+' > source';
  
  // The fastest LazyLoad based on the Intersection Observer (for browsers that support it)
  if ('IntersectionObserver' in window) {
    
    // For the <img> part only
    lazyloadImages = document.querySelectorAll('.'+lazyImgClass);
    var imageObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          var image = entry.target;
          image.src = image.dataset.src;
          image.classList.remove(lazyImgClass);
          imageObserver.unobserve(image);
        }
      });
    });
    lazyloadImages.forEach(function(image) {
      imageObserver.observe(image);
    });
    
    // If we are using the <picture> element and its <source> value
    lazyloadSources = document.querySelectorAll(lazySourceTarget);
    var sourceObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          var source = entry.target;
          source.srcset = source.dataset.srcset;
          source.parentElement.classList.remove(lazyParentClass);
          sourceObserver.unobserve(source);
        }
      });
    });
    lazyloadSources.forEach(function(source) {
      sourceObserver.observe(source);
    });
    
    
    // The slower LazyLoad based on event listeners (for browsers who do not support the Intersection Observer)
  } else {
    var lazyloadThrottleTimeout;
    lazyloadImages = document.querySelectorAll('.'+lazyImgClass);
    lazyloadSources = document.querySelectorAll(lazySourceTarget);
    
    function lazyload () {
      if(lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout);
      }
      
      lazyloadThrottleTimeout = setTimeout(function() {
        var scrollTop = window.pageYOffset;
        lazyloadImages.forEach(function(img) {
          if(img.offsetTop < (window.innerHeight + scrollTop)) {
            img.setAttribute('src', img.dataset.src);
            img.classList.remove(lazyImgClass);
          }
        });
        lazyloadSources.forEach(function(source) {
          if(source.offsetTop < (window.innerHeight + scrollTop)) {
            source.setAttribute('srcset', source.dataset.srcset);
          }
        });
        if(lazyloadImages.length === 0) {
          document.removeEventListener('scroll', lazyload);
          window.removeEventListener('resize', lazyload);
          window.removeEventListener('orientationChange', lazyload);
        }
      }, 20);
    }
    
    document.addEventListener('scroll', lazyload);
    window.addEventListener('resize', lazyload);
    window.addEventListener('load', lazyload);
    window.addEventListener('orientationChange', lazyload);
  }
});