document.querySelectorAll('.wpcf7-validates-as-required').forEach(contentTrigger => contentTrigger.addEventListener('blur', (e) => {
  if (e.target.value !== '' && e.target.classList.contains('wpcf7-not-valid')) {
    e.target.classList.remove('wpcf7-not-valid');
  }
}));

if (window.location.pathname === '/contact/' || window.location.pathname === '/zakelijk/contact/') {
  if (window.location.search.indexOf('vraag=ja') > -1) {
    document.querySelectorAll('input[name=Onderwerpkeuze][value="een vraag stellen"]').forEach(input => input.checked = true);
  }
  else if (window.location.search.indexOf('informatie=ja') > -1) {
    document.querySelectorAll('input[name=Onderwerpkeuze][value="informatie aanvragen"]').forEach(input => input.checked = true);
  }
  else if (window.location.search.indexOf('advies=ja') > -1) {
    document.querySelectorAll('input[name=Onderwerpkeuze][value="persoonlijk advies"]').forEach(input => input.checked = true);
  }
  else if (window.location.search.indexOf('afspraak=ja') > -1) {
    document.querySelectorAll('input[name=Onderwerpkeuze][value="een afspraak maken"]').forEach(input => input.checked = true);
  }
}