const targetBlockWidgetWithImages = '.block-widget.state-bg-img';

function setBlockWidgetImages() {
  document.querySelectorAll(targetBlockWidgetWithImages).forEach(function (targetBlock) {
    let bgValue = targetBlock.querySelector('.bg').dataset.squareImg;
    if (window.innerWidth > layoutBreakpointMedium) {
      bgValue = targetBlock.querySelector('.bg').dataset.tallImg;
    }
    function setImgBg(targetBg) {
      targetBg.setAttribute('style', 'background-image: url("'+bgValue+'");');
    }
    setImgBg(targetBlock.querySelector('.bg'));
  });
}

// Set-up the event triggers.
window.addEventListener('load', function() {
  if (document.querySelectorAll(targetBlockWidgetWithImages).length > 0 && window.innerWidth > layoutBreakpointTiny) {
    setBlockWidgetImages();
  }
});
window.addEventListener('orientationchange', function() {
  if (document.querySelectorAll(targetBlockWidgetWithImages).length > 0 && window.innerWidth < layoutBreakpointTiny) {
    setBlockWidgetImages();
  }
});